let _window: any = {};

if (typeof window !== 'undefined') {
  
  _window = window;
}

let _console: any;
if (typeof console !== 'undefined') {
  _console = console;
} else {
  throw new Error(
    'A console shall be defined in the running environement to use the Logger module.',
  );
}

const help = `
To use these debug tools, make sure you have set isPROD = false\n
\n
 ### LOG ###\n
  Set all loggers level in browser console log: LOG='VERBOSE'; or LOG=5;\n
  Set specific logger level in browser console log: LOG='COMMON.VERBOSE'; or LOG='COMMON.5';\n
  \n
 ### PERFORMANCE ###\n
  To measure API performance, call in browser console: logPerf(); for sorted results or perf; for raw result.
`;

_window['HELP'] = help;
_window['help'] = help;

export enum LOG_LEVELS {
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  DEBUG = 4,
  VERBOSE = 5,
}

/**
 * Global logger configuration will override your logger instance's configuration:
 *   ConsoleLogger.LOG = 'DEBUG';
 *
 * During web development, you can set global log level in browser console log:
 *   window.LOG = 'DEBUG'; or window.LOG = 4;
 *
 * During web development, you can set specific logger log level in browser console log:
 *   window.LOG = 'COMMON.DEBUG'; or window.LOG = 'COMMON.4';
 */
export class ConsoleLogger {
  private static noop = () => {
    return;
  };
  public static LOG: LOG_LEVELS | null = null;

  private loggerName: string;
  private loggerLevel: LOG_LEVELS;
  isPROD: boolean;

  constructor(name: string, level = LOG_LEVELS.WARN) {
    this.loggerName = name;
    this.loggerLevel = level;
    this.isPROD = false;
  }

  private _padding(n: number) {
    return n < 10 ? '0' + n : '' + n;
  }

  private _timeStamp() {
    const date = new Date();
    return (
      [this._padding(date.getMinutes()), this._padding(date.getSeconds())].join(':') +
      '.' +
      date.getMilliseconds()
    );
  }

  private _getPrefix(level: LOG_LEVELS) {
    return `[${LOG_LEVELS[level]} ${this._timeStamp()} ${this.loggerName}]:`;
  }

  /**
   * Tells whether this given level shall be logged.
   * @param level
   */
  isLevelOn(level: LOG_LEVELS): boolean {
    let loggerLevel = this.loggerLevel;
    if (ConsoleLogger.LOG) {
      loggerLevel = ConsoleLogger.LOG;
    }
    if (_window.LOG) {
      let windowLogLevel = _window.LOG.toString();
      const index = windowLogLevel.lastIndexOf('.');
      if (index > 0) {
        if (this.loggerName.toLowerCase() !== windowLogLevel.substring(0, index).toLowerCase()) {
          return false;
        }
        windowLogLevel = windowLogLevel.substring(index + 1);
      }

      const level = this._parseLogLevel(windowLogLevel);
      if (level) loggerLevel = level;
    }
    return loggerLevel >= level;
  }

  /**
   * @param loglevel should be a string matching the enum LOG_LEVELS keys or values
   */
  private _parseLogLevel(loglevel: string): LOG_LEVELS | null {
    const _logLevel = loglevel.toUpperCase();
    
    switch (_logLevel) {
      case 'ERROR':
      case '1':
        return LOG_LEVELS.ERROR;
      case 'WARN':
      case '2':
        return LOG_LEVELS.WARN;
      case 'INFO':
      case '3':
        return LOG_LEVELS.INFO;
      case 'DEBUG':
      case '4':
        return LOG_LEVELS.DEBUG;
      case 'VERBOSE':
      case '5':
        return LOG_LEVELS.VERBOSE;
      default:
        return null;
    }
    
  }

  setLevel(level: LOG_LEVELS) {
    this.loggerLevel = level;
  }

  setIsPROD(isPROD: boolean) {
    this.isPROD = isPROD;
  }

  get verbose(): Function {
    if (!this.isPROD && this.isLevelOn(LOG_LEVELS.VERBOSE)) {
      return console.log.bind(_console, this._getPrefix(LOG_LEVELS.VERBOSE));
    } else {
      return ConsoleLogger.noop;
    }
  }

  get debug(): Function {
    if (!this.isPROD && this.isLevelOn(LOG_LEVELS.DEBUG)) {
      return console.log.bind(_console, this._getPrefix(LOG_LEVELS.DEBUG));
    } else {
      return ConsoleLogger.noop;
    }
  }

  get info(): Function {
    if (!this.isPROD && this.isLevelOn(LOG_LEVELS.INFO)) {
      return console.log.bind(_console, this._getPrefix(LOG_LEVELS.INFO));
    } else {
      return ConsoleLogger.noop;
    }
  }

  get warn(): Function {
    if (!this.isPROD && this.isLevelOn(LOG_LEVELS.WARN)) {
      return console.warn.bind(_console, this._getPrefix(LOG_LEVELS.WARN));
    } else {
      return ConsoleLogger.noop;
    }
  }

  get error(): Function {
    if (!this.isPROD && this.isLevelOn(LOG_LEVELS.ERROR)) {
      return console.error.bind(_console, this._getPrefix(LOG_LEVELS.ERROR));
    } else {
      return ConsoleLogger.noop;
    }
  }
}

export const logger = new ConsoleLogger('COMMON', LOG_LEVELS.INFO);
export const loggerAPI = new ConsoleLogger('API', LOG_LEVELS.INFO);
export const loggerPerf = new ConsoleLogger('PERF', LOG_LEVELS.WARN);

export default logger;
