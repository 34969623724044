import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles/index';
import { ModalStyles } from 'shared/styles/ModalStyles';
import { fontSize, Spacings } from 'shared/styles/index';

export const Styles = StyleSheet.create({
  
  alignItems: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    width: 130,
  },
  leftPanelInputContainer: { width: '100%' },
  rowDirection: { flexDirection: 'row' },

  categoryContainer: {
    width: '100%',
  height: 64,
  zIndex: 600,
  flexDirection: 'column-reverse',
}, 

skillIconOuterContainer: {
  width: 48,
  paddingTop: Spacings.Medium,
  marginRight: Spacings.CardPadding,
},  

categorySelectContainer: { zIndex: 500, width: '100%' },
descriptionContainer: {
  flexWrap: 'wrap',
  paddingLeft: 52,
},
descriptionInputTextContainer: {
  width: '100%',
  backgroundColor: 'transparent',
},    
  leftPanelContainer: {
    width:
      ModalStyles.inputWidthWithAnchor + ModalStyles.anchorWidth + ModalStyles.anchorMarginRight,
    marginRight: ModalStyles.bodyDivider,
  },

  
  leftPanelOuterContainer: {
    flexDirection: 'row',
    zIndex: -1,
  },
  skillIconInnerContainer: {
    backgroundColor: SharedStyles.Colors.Yellow,
    width: 48,
    height: 48,
    borderRadius: 50,
  },
  leftPanelCheckboxContainer: {
    marginBottom: SharedStyles.Spacings.Unit / 2,
    marginRight: SharedStyles.Spacings.Small,
    paddingLeft: 3,
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  skillTextContainer: { width: '100%' },
  skillTextStyle: {
    fontSize: 12,
    fontWeight: 'normal',
    fontFamily: 'Lato,Regular',
    textAlign: 'left',
  },
  skillInputTextStyle: { width: '100%' },
  skillGroupRowContainer: {
    width: '100%',
    zIndex: 800,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: 36,
  },
  skillGroupTextStyle: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  subSkillSrollViewContentContainerStyle: { alignItems: 'center', paddingTop: 16 },
  subSkillSrollViewContainerStyle: { width: '100%', zIndex: -1, maxHeight: 250 },
  skillCardContainerStyle: { width: '80%' },
  expirationRowContainer: {
    width: '100%',
    flexDirection: 'row',
    zIndex: 800,
    justifyContent: 'space-between',
  },
  expirationTextStyle: {
    maxWidth: 130,
  },
  expirationDropDownStyle: { flexDirection: 'column', width: 92 },
  expirationMonthsTextStyle: {
    color: SharedStyles.Colors.Grey,
    fontSize: fontSize.Small,
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontWeight: '600',
    marginTop: 8,
  },
  notificationRowContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    zIndex: 700,
    justifyContent: 'space-between',
  },
  notificationIconContainer: {
    justifyContent: 'flex-start',
    paddingTop: 5,
    marginRight: SharedStyles.Spacings.Small,
  },
  notificationTextStyle: {
    maxWidth: 130,
  },
  notificationDropDownStyle: { width: 92 },
  notificationDaysTextStyle: {
    color: SharedStyles.Colors.Grey,
    fontSize: 12,
    fontFamily: 'Lato, Regular',
    fontWeight: '600',
    fontStyle: 'italic',
    marginTop: 8,
    paddingBottom: 4,
  },
  categoryTextStyle: {
    fontFamily: 'Lato, Regular',
    fontSize: fontSize.Regular,
    fontWeight: 'normal',
    zIndex: 550,
  },
  durationValidityTextStyle: {
    color: SharedStyles.Colors.Black,
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  alertIconContainerStyle: {
    width: 24,
    height: 24,
    marginRight: SharedStyles.Spacings.Small,
    marginBottom: SharedStyles.Spacings.Unit / 2,
  },

  
  midPanelContainer: {
    paddingTop: SharedStyles.Spacings.Unit * 8,
    zIndex: -2,
  },
  midPanelTitleStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Regular,
    width: 226,
  },
  midPanelDivider: { height: SharedStyles.Spacings.Unit * 6 },
  radioItemTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
  },
  radioItemDescriptionStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
  },

  
  rightPanelContainer: {
    flex: 1,
    paddingTop: SharedStyles.Spacings.Unit * 9.5,
  },
  rightPanelListingContainer: { width: '100%', flex: 1, zIndex: 0 },
  listingTitleContainer: {
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: SharedStyles.Spacings.Small,
    zIndex: 0,
  },
  listingTitleTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    color: SharedStyles.Colors.Black,
    fontSize: SharedStyles.fontSize.Regular,
  },
  infoContainerTraining: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: SharedStyles.Spacings.Medium,
  },
  infoContainerPractice: {
    flex: 1,
    flexDirection: 'row',
    marginTop: SharedStyles.Spacings.xMedium,
    marginBottom: SharedStyles.Spacings.Unit,
  },
  infoTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    color: SharedStyles.Colors.MediumGray,
    fontSize: SharedStyles.fontSize.Regular,
    marginTop: 2,
    marginLeft: SharedStyles.Spacings.Unit,
  },
  warningMessageTextStyle: {
    textAlign: 'center',
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    ...SharedStyles.TextFontStyles.LatoRegular,
  },
  trainingsListingContainer: {
    height: '70%',
    zIndex: -1,
  },
  touchableFilterTraining: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
  },
  rightPanelDivider: { height: SharedStyles.Spacings.Unit * 5 },
  trainingDropDownContainer: {
    width: 296,
  },
  trainingDropDownInputListContainer: { marginTop: -27 },
  warningMessageContainer: { zIndex: 0, maxWidth: 296 },
  deletableTrainingCardContainerStyle: { width: 296, marginBottom: SharedStyles.Spacings.xMedium },

  
  deleteButtonContainer: { marginRight: SharedStyles.Spacings.Unit * 15 },
  bottomPanelContainer: {
    paddingRight: SharedStyles.Spacings.Large,
    paddingBottom: SharedStyles.Spacings.Unit * 4,
    paddingTop: SharedStyles.Spacings.Unit * 3,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: ModalStyles.footerHeight,
  },
  saveButtonContainerStyle: { height: 36, paddingHorizontal: 35 },
  saveButtonTextStyle: { fontSize: SharedStyles.fontSize.Regular },

  
  secondaryInputContainer: {
    paddingLeft: ModalStyles.anchorWidth + ModalStyles.anchorMarginRight,
    zIndex: -3,
  },
  skillTagsContainer: { zIndex: -1 },
  attachFileContainer: {
    paddingLeft: SharedStyles.Spacings.Unit / 2,
    paddingTop: SharedStyles.Spacings.Standard,
    marginBottom: 0,
    paddingBottom: 0,
    zIndex: -2,
    width: 280,
  },
  commentsContainer: { zIndex: -3 },
});
